import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import slugify from "slugify"

const AnchorMenu = ({ items }) => {
  const [currentHashLink, setCurrentHashLink] = useState("")

  useEffect(() => {
    window.onscroll = e => {
      const scrollHeights = items.map(i => ({
        id: slugify(i),
        top: Math.ceil(
          document.getElementById(slugify(i)).getBoundingClientRect().top -
            document.body.getBoundingClientRect().top
        ),
        bottom: Math.ceil(
          document.getElementById(slugify(i)).getBoundingClientRect().bottom -
            document.body.getBoundingClientRect().top
        ),
      }))

      for (let i = scrollHeights.length - 1; i >= 0; i--) {
        if (
          window.scrollY + 5 >= scrollHeights[i].top &&
          window.scrollY + 5 < scrollHeights[i].bottom
        ) {
          setCurrentHashLink(scrollHeights[i].id)
          return
        } else {
          setCurrentHashLink("")
        }
      }
    }

    return () => (window.onscroll = null)
  }, [currentHashLink, setCurrentHashLink, items])

  const links = items.map(i => {
    const id = slugify(i)

    return (
      <li key={id} className="d-flex flex-row mb-1 fs-6">
        <div
          className={`border-left border-dark mx-2 ${
            currentHashLink === id ? "border-4" : "border-2"
          }`}
        />
        <Link
          to={`#${id}`}
          className="py-1 d-inline-block text-dark text-truncate text-decoration-none"
        >
          <span className={currentHashLink === id ? "fw-bold" : ""}>{i}</span>
        </Link>
      </li>
    )
  })

  return (
    <ul className="anchor-menu d-none d-lg-block position-fixed left-0 top-50 z-100 list-style-none bg-glass shadow py-3 ps-0 pe-3 rounded-right m-0">
      {links}
    </ul>
  )
}

export default AnchorMenu

import React, { useRef, useEffect, useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Check, Zap, Users, X } from "react-feather"
import Typed from "typed.js"
import slugify from "slugify"

import SEO from "../components/SEO"
import VideoHero from "../components/VideoHero"
import HLS from "../components/HLS"
import Illustration from "../components/Illustration"
import Customers from "../components/Customers"
import AnchorMenu from "../components/AnchorMenu"

import useTranslation from "../translations/translate"

import LogoGreen from "../components/assets/evobend-green.svg"
import IconBendingFreedom from "../components/assets/media/images/bending/icons/bending-freedom.svg"
import IconSpaceSaving from "../components/assets/media/images/bending/icons/spaec-saving.svg"
import IconSafety from "../components/assets/media/images/bending/icons/safety.svg"
import IconMaintenance from "../components/assets/media/images/bending/icons/maintenance-free.svg"
import IconEfficiency from "../components/assets/media/images/bending/icons/efficiency.svg"
import IconInnovation from "../components/assets/media/images/bending/icons/innovation.svg"
import IllustrationBusinessDeal from "../components/assets/media/undraw_business_deal.svg"
import IllustrationFinance from "../components/assets/media/undraw_personal_finance.svg"

const Index = () => {
  const { t } = useTranslation()
  const refTyped = useRef(null)
  const [selectedSize, setSize] = useState(1)
  const highlights = t("page.index.quickFacts.highlights")

  useEffect(() => {
    const options = {
      strings: t("page.index.quickFacts.typed.keywords"),
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
    }
    const typed = new Typed(refTyped.current, options)

    return () => {
      typed.destroy()
    }
  }, [refTyped, t])

  return (
    <>
      <SEO
        title={t("page.index.title")}
        description={t("page.index.description")}
        pageName={"index"}
        path={"/"}
      />

      <VideoHero />
      <AnchorMenu items={Object.values(t("page.index.section"))} />

      <section className="py-10">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4 mb-5 mb-md-0 text-center">
              <p className="h2">{t("page.index.intro.title")}</p>
            </div>

            <div className="col-12 col-md-8 my-auto">
              <p className="lead mb-0">{t("page.index.intro.description")}</p>
            </div>
          </div>
        </div>
      </section>

      <section
        id={slugify(t("page.index.section.machine"))}
        name={t(`page.index.section.machine`)}
        className="bg-light border-top pt-0"
      >
        <div className="container-fluid my-10 text-center">
          <div data-aos="zoom-in">
            <StaticImage
              src="../components/assets/media/images/bending/D300.png"
              className={selectedSize === 0 ? "" : "d-none"}
              alt="EVOBEND D 300"
              layout="fullWidth"
              quality="90"
              breakpoints={[750, 1080, 1366, 1920, 2560]}
            />
            <StaticImage
              src="../components/assets/media/images/bending/D400.png"
              className={selectedSize === 1 ? "" : "d-none"}
              alt="EVOBEND D 400"
              layout="fullWidth"
              quality="90"
              breakpoints={[750, 1080, 1366, 1920, 2560]}
            />
            <StaticImage
              src="../components/assets/media/images/bending/D600.png"
              className={selectedSize === 2 ? "" : "d-none"}
              alt="EVOBEND D 600"
              layout="fullWidth"
              quality="90"
              breakpoints={[750, 1080, 1366, 1920, 2560]}
            />
            <StaticImage
              src="../components/assets/media/images/bending/D800.png"
              className={selectedSize === 3 ? "" : "d-none"}
              alt="EVOBEND D 800"
              layout="fullWidth"
              quality="90"
              breakpoints={[750, 1080, 1366, 1920, 2560]}
            />
          </div>

          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn btn-outline-primary${
                selectedSize === 0 ? " active" : ""
              }`}
              onClick={() => setSize(0)}
            >
              D 300
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary${
                selectedSize === 1 ? " active" : ""
              }`}
              onClick={() => setSize(1)}
            >
              D 400
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary${
                selectedSize === 2 ? " active" : ""
              }`}
              onClick={() => setSize(2)}
            >
              D 600
            </button>
            <button
              type="button"
              className={`btn btn-outline-primary${
                selectedSize === 3 ? " active" : ""
              }`}
              onClick={() => setSize(3)}
            >
              D 800
            </button>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 my-auto" data-aos="fade-right">
              <div className="card border-0 shadow">
                <div className="card-body p-4">
                  <div className="d-flex bg-primary rounded p-4 mb-3 text-white">
                    <span className="me-auto">
                      {t("page.index.factSheet.bendingLength")}
                    </span>
                    <span className="text-end">
                      <p
                        className={`m-0 ${
                          selectedSize === 0 ? "fw-bold" : "small"
                        }`}
                      >
                        3200 mm
                      </p>
                      <p
                        className={`m-0 ${
                          selectedSize === 1 ? "fw-bold" : "small"
                        }`}
                      >
                        4746 mm
                      </p>
                      <p
                        className={`m-0 ${
                          selectedSize === 2 ? "fw-bold" : "small"
                        }`}
                      >
                        6400 mm
                      </p>
                      <p
                        className={`m-0 ${
                          selectedSize === 3 ? "fw-bold" : "small"
                        }`}
                      >
                        8054 mm
                      </p>
                    </span>
                  </div>

                  <div className="d-flex bg-primary rounded p-4 mb-3 text-white">
                    <span className="me-auto">
                      {t("page.index.factSheet.workPerformance")}
                    </span>
                    <span className="text-end">
                      <p className="m-0">
                        {t("page.index.factSheet.material.steel")} ≤ 2 mm{" "}
                        <span className="fw-lighter small ms-3">
                          380N/mm<sup>2</sup>
                        </span>
                      </p>
                      <p className="m-0">
                        {t("page.index.factSheet.material.aluminium")} ≤ 3 mm{" "}
                        <span className="fw-lighter small ms-3">
                          200N/mm<sup>2</sup>
                        </span>
                      </p>
                      <p className="m-0">
                        {t("page.index.factSheet.material.stainlessSteel")} ≤
                        1,25 mm{" "}
                        <span className="fw-lighter small ms-3">
                          600N/mm<sup>2</sup>
                        </span>
                      </p>
                    </span>
                  </div>

                  <div className="d-flex bg-primary rounded p-4 mb-3 text-white">
                    <span className="me-auto">
                      {t("page.index.factSheet.insertionDepth")}
                    </span>
                    <span>1250 mm</span>
                  </div>

                  <div className="d-flex bg-primary rounded p-4 mb-3 text-white">
                    <span className="me-auto">
                      {t("page.index.factSheet.totalDepth")}
                    </span>
                    <span>1880 mm</span>
                  </div>

                  <div className="d-flex bg-primary rounded p-4 mb-3 text-white">
                    <span className="me-auto">
                      {t("page.index.factSheet.totalHeight")}
                    </span>
                    <span>1875 mm</span>
                  </div>

                  <div className="d-flex bg-primary rounded p-4 text-white">
                    <span className="me-auto">
                      {t("page.index.factSheet.totalLength")}
                    </span>
                    <span className="text-end">
                      <p className="m-0">
                        {selectedSize === 0 && <>4600 mm</>}
                        {selectedSize === 1 && <>6146 mm</>}
                        {selectedSize === 2 && <>7800 mm</>}
                        {selectedSize === 3 && <>9454 mm</>}
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-12 col-md-6 ps-5 my-5 my-md-auto"
              data-aos="fade-left"
            >
              <p className="h2">
                {t("page.index.quickFacts.typed.start")}{" "}
                <span ref={refTyped} className="text-info" />
                <br />
                {t("page.index.quickFacts.typed.end")}
              </p>

              <p className="lead text-dark mb-4">
                {t("page.index.quickFacts.researchResult")}
              </p>

              <div className="row">
                <div className="col-12 col-lg-6">
                  {highlights
                    .slice(0, Math.ceil(highlights.length / 2))
                    .map((e, i) => (
                      <div key={i} className="d-flex">
                        <div className="badge badge-icon badge-info-soft rounded-circle mt-1 me-4">
                          <Check />
                        </div>
                        <p className="text-info">{e}</p>
                      </div>
                    ))}
                </div>
                <div className="col-12 col-lg-6">
                  {highlights
                    .slice(Math.ceil(highlights.length / 2), highlights.lenght)
                    .map((e, i) => (
                      <div key={i} className="d-flex">
                        <div className="badge badge-icon badge-info-soft rounded-circle mt-1 me-4">
                          <Check />
                        </div>
                        <p className="text-info">{e}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id={slugify(t("page.index.section.features"))}
        name={t(`page.index.section.features`)}
        className="bg-light border-bottom pt-0"
      >
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            <div className="col mb-5" data-aos="fade-up">
              <div className="card h-100 shadow-sm border-0">
                <div className="card-body px-6 pb-6">
                  <div className="d-flex justify-content-center my-10">
                    <div className="w-75 p-6 bg-info-soft rounded-circle">
                      <img src={IconBendingFreedom} alt="" className="w-100" />
                    </div>
                  </div>
                  <p className="h2 card-title text-center my-6">
                    {t("page.index.features.bendingFreedom.title")}
                  </p>
                  {t("page.index.features.bendingFreedom.highlights").map(
                    (h, i) => (
                      <div key={i} className="d-flex">
                        <div className="badge badge-icon badge-primary-soft rounded-circle mt-1 me-4">
                          <Check />
                        </div>
                        <p className="text-primary">{h}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="col mb-5" data-aos="fade-up" data-aos-delay="200">
              <div className="card h-100 shadow-sm border-0">
                <div className="card-body px-6 pb-6">
                  <div className="d-flex justify-content-center my-10">
                    <div className="w-75 p-8 bg-info-soft rounded-circle">
                      <img src={IconEfficiency} alt="" className="w-100" />
                    </div>
                  </div>
                  <p className="h2 card-title text-center my-6">
                    {t("page.index.features.energyEfficient.title")}
                  </p>
                  {t("page.index.features.energyEfficient.highlights").map(
                    (h, i) => (
                      <div key={i} className="d-flex">
                        <div className="badge badge-icon badge-primary-soft rounded-circle mt-1 me-4">
                          <Check />
                        </div>
                        <p className="text-primary">{h}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="col mb-5" data-aos="fade-up" data-aos-delay="400">
              <div className="card h-100 shadow-sm border-0">
                <div className="card-body px-6 pb-6">
                  <div className="d-flex justify-content-center my-10">
                    <div className="w-75 p-8 bg-info-soft rounded-circle">
                      <img src={IconSafety} alt="" className="w-100" />
                    </div>
                  </div>
                  <p className="h2 card-title text-center my-6">
                    {t("page.index.features.safety.title")}
                  </p>
                  {t("page.index.features.safety.highlights").map((h, i) => (
                    <div key={i} className="d-flex">
                      <div className="badge badge-icon badge-primary-soft rounded-circle mt-1 me-4">
                        <Check />
                      </div>
                      <p className="text-primary">{h}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="col mb-5" data-aos="fade-up">
              <div className="card h-100 shadow-sm border-0">
                <div className="card-body px-6 pb-6">
                  <div className="d-flex justify-content-center my-10">
                    <div className="w-75 p-8 bg-info-soft rounded-circle">
                      <img src={IconMaintenance} alt="" className="w-100" />
                    </div>
                  </div>
                  <p className="h2 card-title text-center my-6">
                    {t("page.index.features.maintenance.title")}
                  </p>
                  {t("page.index.features.maintenance.highlights").map(
                    (h, i) => (
                      <div key={i} className="d-flex">
                        <div className="badge badge-icon badge-primary-soft rounded-circle mt-1 me-4">
                          <Check />
                        </div>
                        <p className="text-primary">{h}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="col mb-5" data-aos="fade-up" data-aos-delay="200">
              <div className="card h-100 shadow-sm border-0">
                <div className="card-body px-6 pb-6">
                  <div className="d-flex justify-content-center my-10">
                    <div className="w-75 p-8 bg-info-soft rounded-circle">
                      <img src={IconInnovation} alt="" className="w-100" />
                    </div>
                  </div>
                  <p className="h2 card-title text-center my-6">
                    {t("page.index.features.innovation.title")}
                  </p>
                  {t("page.index.features.innovation.highlights").map(
                    (h, i) => (
                      <div key={i} className="d-flex">
                        <div className="badge badge-icon badge-primary-soft rounded-circle mt-1 me-4">
                          <Check />
                        </div>
                        <p className="text-primary">{h}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="col mb-5" data-aos="fade-up" data-aos-delay="400">
              <div className="card h-100 shadow-sm border-0">
                <div className="card-body px-6 pb-6">
                  <div className="d-flex justify-content-center my-10">
                    <div className="w-75 p-8 bg-info-soft rounded-circle">
                      <img src={IconSpaceSaving} alt="" className="w-100" />
                    </div>
                  </div>
                  <p className="h2 card-title text-center my-6">
                    {t("page.index.features.spaceSaving.title")}
                  </p>
                  {t("page.index.features.spaceSaving.highlights").map(
                    (h, i) => (
                      <div key={i} className="d-flex">
                        <div className="badge badge-icon badge-primary-soft rounded-circle mt-1 me-4">
                          <Check />
                        </div>
                        <p className="text-primary">{h}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id={slugify(t("page.index.section.bendingSystem"))}
        name={t(`page.index.section.bendingSystem`)}
        className="py-0"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 min-vh-100 d-flex flex-column justify-content-center pt-12 pb-md-12">
              <p className="h1">
                <span className="text-info fw-bold">
                  {t("page.index.bendingSystem.title")}
                </span>
                <br />
                <span className="text-decoration-line-through">
                  {t("page.index.bendingSystem.subtitle")}
                </span>
              </p>

              <p className="text-muted">{t("page.index.bendingSystem.body")}</p>

              {t("page.index.bendingSystem.features").map((f, i) => (
                <div key={i} className="d-flex mb-5">
                  <div className="badge badge-icon badge-info-soft rounded-circle mt-1">
                    <Check />
                  </div>

                  <div className="ms-5">
                    <p className="h4 mb-1">{f.title}</p>

                    <p className="text-muted">{f.body}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-12 col-md-6 position-md-relative pb-6 p-md-0">
              <div className="h-100 w-md-cover position-md-relative">
                <HLS
                  videoSrc="https://stream.mux.com/z21oyLrQFouKlxLktM021L4M7ZW8Lzu3LjN1o381VSjg.m3u8"
                  className="object-position-center object-fit-cover position-md-absolute h-100 w-100 rounded rounded-md-0"
                  autoPlay
                  playsInline
                  muted
                  loop
                />
              </div>

              <div className="shape shape-left shape-fluid-y text-white d-none d-md-block">
                <svg
                  viewBox="0 0 150 1544"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 0h150v386l-75 772v386H0V0z" fill="currentColor" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id={slugify(t("page.index.section.software"))}
        name={t(`page.index.section.software`)}
        className="min-vh-100 d-flex align-items-center bg-light border-top border-bottom"
      >
        <div className="container">
          <div className="row row-cols-1 row-cols-md-2 align-items-center">
            <div className="col mb-5 mb-md-0" data-aos="fade-right">
              <p className="h1">
                <span className="text-info fw-bold">
                  {t("page.index.software.title")}
                </span>
                <br />
                {t("page.index.software.subtitle")}
              </p>

              <p className="font-size-lg text-muted mb-5">
                {t("page.index.software.body")}
              </p>

              <div className="d-flex mb-5">
                <div className="badge badge-icon badge-info-soft rounded-circle mt-1">
                  <Zap />
                </div>

                <div className="ms-5">
                  <p className="h4 mb-1">
                    {t("page.index.software.features.ux.title")}
                  </p>

                  <p className="text-muted">
                    {t("page.index.software.features.ux.body")}
                  </p>
                </div>
              </div>

              <div className="d-flex">
                <div className="badge badge-icon badge-info-soft rounded-circle mt-1">
                  <Users />
                </div>

                <div className="ms-5">
                  <p className="h4 mb-1">
                    {t("page.index.software.features.support.title")}
                  </p>

                  <p className="text-muted">
                    {t("page.index.software.features.support.body")}
                  </p>
                </div>
              </div>
            </div>

            <div className="col" data-aos="fade-left">
              <div className="w-md-150 w-lg-130 position-relative">
                <div className="img-skewed img-skewed-left rounded">
                  <StaticImage
                    src="../components/assets/media/images/bending/bendware.png"
                    className="img-skewed-item screenshot rounded"
                    layout="fullWidth"
                    alt="BENDWARE EVOBEND SOFTWARE"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id={slugify(t("page.index.section.competition"))}
        name={t(`page.index.section.competition`)}
        className="min-vh-100 d-flex align-items-center"
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-7 col-lg-6 pe-0">
              <div className="card z-10 shadow rounded-left">
                <div className="card-body d-flex flex-column align-items-center">
                  <p className="h1 mb-0 fw-bold">
                    {t("page.index.comparison.we.title")}
                  </p>
                  <p className="h2 mb-6 fw-lighter text-uppercase">
                    {t("page.index.comparison.we.subtitle")}
                  </p>

                  <div>
                    {t("page.index.comparison.we.points").map((p, i) => (
                      <div key={i}>
                        <p className="h2 mb-3">{p.title}</p>

                        {p.body.map((body, i) => (
                          <div
                            key={i}
                            className="d-flex align-items-center mb-3"
                          >
                            <div className="badge badge-icon badge-success-soft rounded-circle me-2">
                              <Check />
                            </div>
                            <p className="lead m-0">{body}</p>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <Link
                    to="/contact"
                    role="button"
                    className="btn btn-primary mt-6"
                  >
                    {t("common.contactWithoutObligation")}
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-5 col-lg-4 ps-0">
              <div className="card bg-light shadow-sm rounded-left-0 border-left-0">
                <div className="card-body d-flex flex-column align-items-center">
                  <p className="h2 text-center fw-lighter">
                    {t("page.index.comparison.competition.title")}
                  </p>

                  <div>
                    {t("page.index.comparison.competition.points").map(
                      (p, i) => (
                        <div key={i}>
                          <p className="h3 fw-lighter mt-6">{p.title}</p>
                          {p.body.map((l, i) => (
                            <div
                              key={i}
                              className="d-flex align-items-center mb-3"
                            >
                              <div className="badge badge-icon badge-danger-soft rounded-circle me-2">
                                <X />
                              </div>
                              <p className="lead m-0">{l}</p>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id={slugify(t("page.index.section.sustainability"))}
        name={t(`page.index.section.sustainability`)}
        className="position-relative min-vh-100 d-flex align-items-center"
      >
        <div className="container z-10">
          <p className="display-1 fw-bold text-center text-white stroke-black mb-12">
            {t("page.index.sustainability.title")}
          </p>

          <div className="row justify-content-center">
            <div className="col col-lg-10">
              <div className="card shadow">
                <div className="card-body">
                  <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center mb-8">
                    <img src={LogoGreen} height="55" alt="EVOBEND logo" />
                    <p className="text-eco h1 mt-5 mt-md-0 text-center text-md-end">
                      {t("page.index.sustainability.subtitle", true)}
                    </p>
                  </div>

                  <div className="row align-items-center">
                    <div className="col col-md-6 col-lg-5 mb-5 mb-md-0">
                      {t("page.index.sustainability.quickFacts").map((f, i) => (
                        <div key={i} className="d-flex">
                          <div className="badge badge-icon badge-eco-soft rounded-circle mt-1 me-4">
                            <Check />
                          </div>
                          <p className="text-eco">
                            <span className="fw-bold">{f.title}</span>
                            <br />
                            {f.body}
                          </p>
                        </div>
                      ))}
                    </div>

                    <div className="col col-md-6 col-lg-7">
                      <p className="lead text-eco m-0">
                        {t("page.index.sustainability.body")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <video
          src="/media/sustainability.mp4"
          className="object-position-center object-fit-cover position-absolute top-0 left-0 h-100 w-100 z-n100 blur"
          autoPlay
          playsInline
          muted
          loop
        />
      </section>

      <Customers />

      <section
        id={slugify(t("page.index.section.service"))}
        name={t(`page.index.section.service`)}
        className="container"
      >
        <div className="row align-items-center">
          <div className="col col-md-6" data-aos="fade-right">
            <div className="card shadow">
              <div className="card-body d-flex flex-column">
                <p className="h2 mb-5">
                  <span className="text-info fw-bolder">
                    {t("page.index.service.buyBack.title")}
                  </span>
                  <br />
                  {t("page.index.service.buyBack.subtitle")}
                </p>

                <p className="mb-5">{t("page.index.service.buyBack.body")}</p>

                <Link
                  to="/contact"
                  role="button"
                  className="btn btn-danger mx-auto shadow"
                >
                  {t("common.contactWithoutObligation")}
                </Link>
              </div>
            </div>
          </div>

          <div className="col-6 d-none d-md-block" data-aos="fade-left">
            <Illustration src={IllustrationBusinessDeal} />
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row align-items-center">
          <div className="col-6 d-none d-md-block" data-aos="fade-right">
            <Illustration src={IllustrationFinance} />
          </div>

          <div className="col col-md-6" data-aos="fade-left">
            <div className="card shadow">
              <div className="card-body d-flex flex-column">
                <p className="h2 mb-5">
                  <span className="text-info fw-bolder">
                    {t("page.index.service.financing.title")}
                  </span>
                  <br />
                  {t("page.index.service.financing.subtitle")}
                </p>

                <p className="mb-5">{t("page.index.service.financing.body")}</p>

                <Link
                  to="/contact"
                  role="button"
                  className="btn btn-danger mx-auto shadow"
                >
                  {t("common.contactWithoutObligation")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid p-0">
        <StaticImage
          src="../components/assets/media/images/bending/machine_dark.jpg"
          alt="EVOBEND"
          layout="fullWidth"
          quality="90"
          breakpoints={[750, 1080, 1366, 1920, 2560]}
        />
      </section>
    </>
  )
}

export default Index

import React from "react"
import useTranslation from "../translations/translate"

import LogoWeissbacher from "./assets/media/images/customers/weissbacher.svg"
import LogoSteffnerdach from "./assets/media/images/customers/steffnerdach.svg"
import LogoInnocente from "./assets/media/images/customers/innocente.svg"
import LogoHeigl from "./assets/media/images/customers/heigl.svg"
import LogoBerner from "./assets/media/images/customers/berner.png"

const Customers = ({ className }) => {
  const { t } = useTranslation()

  return (
    <section className="bg-light border-top border-bottom">
      <div className="container">
        <p className="h1 mb-8">{t("component.customers.description")}</p>

        <div className="row row-cols-2 row-cols-md-5 justify-content-between align-items-center text-center">
          <div className="col px-5">
            <img src={LogoBerner} alt="" className="customer-logo img-fluid" />
          </div>

          <div className="col px-5">
            <img
              src={LogoWeissbacher}
              alt=""
              className="customer-logo img-fluid"
            />
          </div>

          <div className="col px-5">
            <img
              src={LogoSteffnerdach}
              alt=""
              className="customer-logo img-fluid"
            />
          </div>

          <div className="col px-5">
            <img
              src={LogoInnocente}
              alt=""
              className="customer-logo img-fluid"
            />
          </div>

          <div className="col px-5">
            <img src={LogoHeigl} alt="" className="customer-logo img-fluid" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Customers

import React, { useEffect, useRef } from "react"
import HlsJS from "hls.js"

const HLS = props => {
  const refVideo = useRef(null)

  useEffect(() => {
    const video = refVideo.current
    const videoSrc = props.videoSrc

    if (HlsJS.isSupported()) {
      const h = new HlsJS()
      h.loadSource(videoSrc)
      h.attachMedia(video)
      h.on(HlsJS.Events.MANIFEST_PARSED, () => {
        if (props.autoPlay) {
          video.play()
        }
      })
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = props.videoSrc
      video.addEventListener("loadedmetadata", () => {
        if (props.autoPlay) {
          video.play()
        }
      })
    }
  }, [props.videoSrc, props.autoPlay, refVideo])

  const attributes = { ...props }
  delete attributes.videoSrc

  // eslint-disable-next-line
  return <video ref={refVideo} {...attributes} />
}

export default HLS

import React, { useEffect, useRef, useState } from "react"
import { PauseCircle, PlayCircle } from "react-feather"
import Hls from "hls.js"

import Navbar from "./Navbar"

import useTranslation from "../translations/translate"

const videoUrl =
  "https://stream.mux.com/r2S5PSkXidvC8EnL25x313I9JUnUggkcqN00NB19zrME.m3u8"

const VideoHero = () => {
  const { t } = useTranslation()

  const [isLoading, setLoading] = useState(true)
  const [isPlaying, setPlaying] = useState(false)
  const refVideo = useRef(null)

  const play = () => {
    if (!!refVideo.current) {
      refVideo.current.play()
      setPlaying(true)
    }
  }

  const pause = () => {
    if (!!refVideo.current) {
      refVideo.current.pause()
      setPlaying(false)
    }
  }

  useEffect(() => {
    const video = refVideo.current

    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(videoUrl)
      hls.attachMedia(video)
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        setLoading(false)
        setPlaying(true)
        video.play()
      })
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = videoUrl
      video.addEventListener("loadedmetadata", () => {
        setLoading(false)
        setPlaying(true)
        video.play()
      })
    }
  }, [refVideo, setLoading])

  return (
    <section className="hero p-0 video-bg-wrapper">
      <div className="h-100 video-bg-content text-white d-flex flex-column justify-content-between">
        <Navbar color="dark" />

        <div className="container hero-content mt-auto mb-5">
          <h1 className="display-3 fw-bold stroke-black-lg pb-1">
            {t("page.index.hero.title", true)}
          </h1>
          <div className="d-flex flex-row justify-content-between">
            <p className="display-3">{t("page.index.hero.subtitle")}</p>

            <button
              type="button"
              className="btn mt-auto p-1"
              onClick={isPlaying ? pause : play}
              aria-label={isPlaying ? t("common.pause") : t("common.resume")}
            >
              {isPlaying ? (
                <PauseCircle className="text-white" size="42" strokeWidth="1" />
              ) : (
                <PlayCircle className="text-white" size="42" strokeWidth="1" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="video-bg">
        <video
          ref={refVideo}
          className={isLoading ? "d-none" : "h-100 w-100"}
          autoPlay
          playsInline
          muted
          loop
        />
      </div>
    </section>
  )
}

export default VideoHero
